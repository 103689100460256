import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import { SearchOutlined } from '@material-ui/icons'
import { Component } from 'react'
import { limitTextLength } from '../../../helpers/limitTextLength'
import Product from '../../../interfaces/product'
import styles from './styles'

interface Props {
  classes: Record<keyof ReturnType<typeof styles>, string>
  open: boolean
  handleClose: () => void
  handleSubmit: (product: Product) => void
  getProducts: (...args: any) => Promise<void>
  products: Product[]
  fetching: any
}

interface State {
  EAN: string
  product?: Product
  fetched: boolean
  fetching: boolean
  wasSearched: boolean
}

class ProductSearchDialog extends Component<Props, State> {
  state: State = {
    EAN: '',
    fetched: false,
    fetching: false,
    wasSearched: false,
  }

  handleState = (field: any, value: any) => {
    this.setState({
      ...this.state,
      [field]: value,
    })
  }

  searchByEAN = async () => {
    const { EAN } = this.state
    const { getProducts } = this.props

    this.setState({
      fetching: true,
      wasSearched: EAN.length > 0,
    })

    await getProducts({ query: EAN, onAdmin: true })

    this.setState({
      fetched: true,
      fetching: false,
    })
  }

  handleGetProducts = async () => {
    const { getProducts } = this.props

    await getProducts({ onAdmin: true })
  }

  async componentDidMount() {
    this.setState({
      fetching: true,
    })

    await this.handleGetProducts()

    this.setState({
      fetching: false,
    })
  }

  render() {
    const { EAN, product, fetched, fetching } = this.state
    const { open, handleClose, handleSubmit, classes, products } = this.props

    return (
      <Dialog open={open}>
        <DialogTitle>
          <Typography
            style={{
              fontSize: '24px',
              color: '#1999F9',
              fontWeight: '600',
            }}
          >
            Procurar produto
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Divider
            style={{
              marginBottom: '16px',
              backgroundColor: '#e9e9e9',
            }}
          />

          <Typography
            variant="body2"
            gutterBottom
            classes={{
              root: classes.description,
            }}
          >
            Para cadastrar o produto, busque-o utilizando o código de barras (EAN). Se encontrarmos o item em nosso catálogo,
            basta clicar nele para completar as informações e utilizar o produto já cadastrado. Caso não tenhamos o mesmo, será
            necessário cadastrá-lo de forma manual.
          </Typography>
          <Box mt={3} mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="EAN"
              classes={{
                root: classes.textfield,
              }}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={this.searchByEAN} disabled={!(EAN.length === 0 || EAN.length >= 12)}>
                    <SearchOutlined color={!(EAN.length === 0 || EAN.length >= 12) ? 'disabled' : 'primary'} />
                  </IconButton>
                ),
              }}
              value={EAN}
              onChange={(e) => this.handleState('EAN', e.target.value)}
            />
          </Box>
          {fetching && <LinearProgress />}
          {fetched && !fetching && (
            <Typography align="center">
              {`${products.length} ${products.length === 1 ? 'produto encontrado' : 'produtos encontrados'}`}
            </Typography>
          )}
          <Box mb={3} mt={3}>
            <Grid className={classes.wrapperProducts} container spacing={1} justify="flex-start" alignItems="center">
              {products.map((value: Product, index: number) => (
                <Grid item key={index}>
                  <Button
                    onClick={() => {
                      this.setState({ wasSearched: false })
                      handleSubmit(value)
                    }}
                    // onClick={() => this.handleState('product', value)}
                    color={product && product._id === value._id ? 'primary' : 'default'}
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        width: '100%',
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: '14px',
                          fontWeight: 500,
                        }}
                      >
                        {limitTextLength(`${value.name ?? ''} ${value.presentation ?? ''}`, 60)}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: '14px',
                          fontWeight: 500,
                          color: '#71717a',
                        }}
                      >
                        {value.EAN}
                      </Typography>
                    </Box>
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions style={{ paddingBottom: '16px', paddingRight: '20px' }}>
          <Grid container justify="flex-end" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={EAN.length < 13 || !this.state.wasSearched || products?.length > 0}
                onClick={handleClose}
              >
                Cadastrar manualmente
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(ProductSearchDialog)
