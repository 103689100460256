import { useEffect, useMemo, useState } from 'react'
import { LinearProgress } from '../../../LinearProgress'
import { Container, Title } from './styles'

type Props = { onFinish(): void }

const messages = [
  { min: 0, max: 33, label: 'Iniciando Atualização do Catálogo' },
  { min: 33, max: 66, label: 'Atualização do Catálogo, em andamento...' },
  { min: 66, max: 100, label: 'Estamos quase lá, finalizando...' },
]
export default function Progress({ onFinish }: Props) {
  const [percentage, setPercentage] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      setPercentage(perc => Math.min(100, perc + 1))
      if (percentage === 100) onFinish()
    }, 50)
    return () => {
      clearInterval(interval)
    }
  }, [onFinish, percentage])
  const label = useMemo(() => {
    return messages.find(msg => percentage >= msg.min && percentage < msg.max)?.label
  }, [percentage])
  return <Container>
    <LinearProgress progress={percentage} />
    <Title variant={'h5'}>{label}</Title>
  </Container>
}