import { Box,  Theme, Typography } from '@material-ui/core'
import { styled } from '@material-ui/styles'

export const Title = styled(Typography)(({theme}) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 500,
  color: '#474f57',
  textAlign: 'center'
}))

export const Container = styled(Box)(({theme}: {theme: Theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(2)
}))

