import { Button, CircularProgress, Grid, Typography, withStyles } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { isEqual } from 'lodash'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import { ReactComponent as GoBackArrow } from '../../../assets/images/icons/blueBackArrow.svg'
import { getFullPrice } from '../../../helpers/getFullPrice'
import ImportData from '../../../interfaces/importData'
import Product from '../../../interfaces/product'
import Store from '../../../interfaces/store'
import ProductSearchDialog from '../../Dialogs/ProductSearchDialog'
import ProductForm from '../../Forms/ProductForm'
import styles from './styles'

interface LocationProps {
  product: ImportData | null
}

interface Props {
  mode: any
  store?: Store
  success: boolean
  products: Product[]
  product: Product | null
  importValue: LocationProps
  loadControls: (data?: any) => void
  loadCategories: (data?: any) => void
  loadSubCategories: (data?: any) => Promise<void>
  loadProductDetail: (data?: any) => void
  onSave: (data: Product) => Promise<void>
  loadManufacturers: (data?: any) => void
  loadClassifications: (data?: any) => void
  openSnackbar: (message: string, severity?: 'error' | 'info' | 'success' | 'warning') => void
  getProducts: (...args: any) => Promise<void>
  classes: Record<keyof ReturnType<typeof styles>, string>
}

interface State {
  searchModal: boolean
}

class NewProductPaper extends Component<Props, State> {
  static defaultProps = {
    store: undefined,
    products: [],
    product: null,
    success: false,
  }
  state: State = {
    searchModal: true,
  }

  componentDidMount() {
    const { loadControls, loadCategories, loadClassifications, loadManufacturers, getProducts } = this.props
    getProducts({ EAN: 'unknown' })
    loadControls()
    loadCategories()
    loadManufacturers()
    loadClassifications()
  }

  handleModal = () => {
    this.setState({
      ...this.state,
      searchModal: !this.state.searchModal,
    })
  }

  save = async (data: Product) => {
    const { onSave, success, openSnackbar } = this.props

    await onSave(data)
    if (success) {
      openSnackbar('Produto criado com sucesso')
    } else {
      openSnackbar('Falha ao criar produto')
    }
  }

  validationSchema = yup.object({
    EAN: yup.string().required('EAN é obrigatório'),
    presentation: yup.string().required('Apresentação é obrigatório'),
    description: yup.string().required('Descrição é obrigatório'),
    name: yup.string().required('Name é obrigatório'),
    quantity: yup.number().required('Estoque é obrigatório'),
    status: yup.bool().required('Status é obrigatório'),
    manualPMC: yup.boolean(),
    MS: yup.string().nullable(),
    erp_pmc: yup
      .number()
      .required('O preço do produto é obrigatório')
      .min(0.2, 'Valor mínimo é de R$ 0,20')
      .test({
        name: 'erppmcValid',
        test(erp_pmc, ctx) {
          const { price } = this.parent

          if (erp_pmc && erp_pmc < price) {
            return ctx.createError({
              message: 'O preço do produto deve ser maior que o preço promocional',
            })
          }

          if (erp_pmc && erp_pmc === price) {
            return ctx.createError({
              message: 'O preço do produto não pode ser igual ao preço promocional',
            })
          }

          return true
        },
      }),
    price: yup.number().test({
      name: 'priceValid',
      test(price, ctx) {
        const { erp_pmc } = this.parent

        if (price && price > 0 && price > erp_pmc) {
          return ctx.createError({
            message: 'O preço promocional deve ser menor que o preço do produto',
          })
        }

        if (price && price > 0 && price === erp_pmc) {
          return ctx.createError({
            message: 'O preço promocional não pode ser igual ao preço do produto',
          })
        }

        return true
      },
    }),
    _id: yup.string(),
    width: yup.number(),
    weight: yup.number(),
    length: yup.number(),
    height: yup.number(),
  })

  render() {
    const { products, getProducts, classes, mode, store, product, importValue, loadSubCategories } = this.props
    const { searchModal } = this.state
    const fullPrice = getFullPrice(Number(importValue?.product?.price) || 0, Number(importValue?.product?.erp_pmc) || 0)
    const discountPrice = Number(importValue?.product?.price) < fullPrice ? Number(importValue?.product?.price) : 0.0

    return (
      <React.Fragment>
        <Formik
          initialValues={{
            MS: '',
            EAN: importValue && importValue.product ? importValue.product.EAN : '',
            name: importValue && importValue.product ? importValue.product.name : '',
            slug: [],
            price: discountPrice,
            erp_pmc: fullPrice,
            model: '',
            pmcPrice: 0,
            quantity: importValue && importValue.product ? Number(importValue.product.quantity) : 0,
            category: [],
            status: true,
            specials: [],
            metaTitle: '',
            description: '',
            manualPMC: false,
            presentation: importValue && importValue.product ? importValue.product.presentation : '',
            image: undefined,
            activePrinciple: '',
            metaDescription: '',
            controlName: '',
            classificationName: '',
            manufacturerName: '',
            width: 0,
            weight: 0,
            length: 0,
            height: 0,
            product,
            isCatalog: false,
          }}
          onSubmit={this.save}
          validationSchema={this.validationSchema}
          enableReinitialize
        >
          {({ isSubmitting, isValid, setValues, values, initialValues }) => (
            <Form>
              <Grid container alignItems="center" justify="space-between" className={classes.header}>
                <Grid item className={classes.headergrid1} xs={12} md={12} lg={6}>
                  <Grid container alignItems="center" spacing={2}>
                    <Link to="/products" className={classes.goback}>
                      <GoBackArrow />
                    </Link>
                    <Typography className={classes.gobacktext}>Adicionar produto</Typography>
                  </Grid>
                </Grid>
                <Grid container={window.innerWidth < 1200} alignItems="center" justify="space-between">
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting || isEqual(initialValues, values)}
                  >
                    {isSubmitting ? <CircularProgress size={20} /> : 'Salvar'}
                  </Button>
                </Grid>
              </Grid>
              <ProductForm store={store} mode={mode} isCatalog={values.isCatalog} />
              <ProductSearchDialog
                open={searchModal}
                handleClose={this.handleModal}
                products={products}
                getProducts={getProducts}
                fetching={isSubmitting}
                handleSubmit={async (data: Product) => {
                  await loadSubCategories(data.category?.[0]?._id ?? '')

                  setValues({
                    ...values,
                    ...data,
                    isCatalog: true,
                    controlName: data && data.control && data.control.description ? data.control.description : '',
                    classificationName: data && data.classification && data.classification.name ? data.classification.name : '',
                    manufacturerName: data && data.manufacturer && data.manufacturer.name ? data.manufacturer.name : '',
                    erp_pmc: getFullPrice(Number(data.price) || 0, Number(data.erp_pmc) || 0),
                  })
                  this.handleModal()
                }}
              />
              <Grid container={window.innerWidth < 1200} alignItems="center" justify="space-between" md={12}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting || !isValid || JSON.stringify(initialValues) === JSON.stringify(values)}
                >
                  {isSubmitting ? <CircularProgress size={20} /> : 'Salvar'}
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(NewProductPaper)
