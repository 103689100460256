import { Stepper } from '@material-ui/core'
import styled from 'styled-components'

export const CatalogTermsStepper = styled(Stepper)({

  width: '100%',
  height: '100%',
  paddingLeft: 0,
  paddingRight: 0,
  '.MuiStepIcon-root': {
    width: "3rem",
    height: "3rem",
    zIndex: 1,
  },
  '.MuiStepConnector-root': {
    marginTop: ".8rem",
  },
  '.MuiStepConnector-line': {
    margin: '0 .25rem'
  }
})
