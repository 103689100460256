import { Button, CircularProgress, Grid, LinearProgress, Typography, withStyles } from '@material-ui/core'
import { Component } from 'react'
import Product from '../../../interfaces/product'

import { Form, Formik } from 'formik'
import { isEqual } from 'lodash'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import { ReactComponent as GoBackArrow } from '../../../assets/images/icons/blueBackArrow.svg'
import { getFullPrice } from '../../../helpers/getFullPrice'
import Store from '../../../interfaces/store'
import ProductForm from '../../Forms/ProductForm'
import styles from './styles'

interface Props {
  classes: Record<keyof ReturnType<typeof styles>, string>
  onSave: (data: Product) => Promise<void>
  id: Product['_id']
  product?: any
  products: Product[]
  mode: any
  loadControls: (data?: any) => void
  loadCategories: (data?: any) => void
  store?: Store
  loadProductDetail: (data?: any) => void
  loadManufacturers: (data?: any) => void
  loadClassifications: (data?: any) => void
  getProduct: (_id: any) => Promise<void>
  openSnackbar: (message: string, severity?: 'error' | 'info' | 'success' | 'warning') => void
  fetching: any
  success: any
  loadSubCategories: (data?: any) => Promise<void>
}

class UpdateProductPaper extends Component<Props> {
  async componentDidMount() {
    const { id, getProduct, loadControls, loadCategories, loadClassifications, loadManufacturers } = this.props
    loadControls()
    loadCategories()
    loadManufacturers()
    loadClassifications()
    await getProduct(id)
  }

  async componentDidUpdate(prevProps: Props) {
    const { product, loadSubCategories } = this.props
    if (prevProps.product !== product) {
      if (product.category && product.category.length > 0 && product.category[0]) {
        await loadSubCategories(product.category?.[0]?._id ?? '')
      }
    }
  }

  save = async (data: Product) => {
    const { onSave, success, openSnackbar } = this.props
    await onSave(data)
    if (success) {
      openSnackbar('Produto atualizado com sucesso')
    } else {
      openSnackbar('Falha ao criar produto')
    }
  }

  validationSchema = yup.object({
    _id: yup.string(),
    status: yup.bool(),
    quantity: yup.number(),
    manualPMC: yup.boolean(),
    description: yup.string(),
    presentation: yup.string(),
    MS: yup.string().nullable(),
    EAN: yup.string().required('EAN é obrigatório'),
    name: yup.string().required('Name é obrigatório'),
    erp_pmc: yup
      .number()
      .required('O preço do produto é obrigatório')
      .min(0.2, 'Valor mínimo é de R$ 0,20')
      .test({
        name: 'erppmcValid',
        test(erp_pmc, ctx) {
          const { price } = this.parent

          if (erp_pmc && erp_pmc < price) {
            return ctx.createError({
              message: 'O preço do produto deve ser maior que o preço promocional',
            })
          }

          if (erp_pmc && erp_pmc === price) {
            return ctx.createError({
              message: 'O preço do produto não pode ser igual ao preço promocional',
            })
          }

          return true
        },
      }),
    price: yup.number().test({
      name: 'priceValid',
      test(price, ctx) {
        const { erp_pmc } = this.parent

        if (price && price > 0 && price > erp_pmc) {
          return ctx.createError({
            message: 'O preço promocional deve ser menor que o preço do produto',
          })
        }

        if (price && price > 0 && price === erp_pmc) {
          return ctx.createError({
            message: 'O preço promocional não pode ser igual ao preço do produto',
          })
        }

        return true
      },
    }),
  })

  render() {
    const {
      fetching,
      mode,
      store,
      classes,
      product = {
        EAN: '',
        name: '',
        model: '',
        slug: [],
        quantity: 0,
        status: true,
        metaTitle: '',
        metaDescription: '',
        MS: null,
        presentation: '',
        activePrinciple: '',
        specials: [],
        manualPMC: false,
        pmcPrice: 0,
        category: [],
        priceLocked: false,
        quantityLocked: false,
      },
    } = this.props
    return (
      <Formik
        initialValues={{
          ...product,
          price: product ? Number(product.price) : 0,
          erp_pmc: getFullPrice(Number(product?.price) || 0, Number(product?.erp_pmc)),
          controlName: product.control && product.control.description ? product.control.description : '',
          classificationName: product.classification && product.classification.name ? product.classification.name : '',
          manufacturerName: product.manufacturer && product.manufacturer.name ? product.manufacturer.name : '',
          subcategory:
            product &&
            product.category &&
            product.category[0] &&
            product.category[0].subCategories &&
            product.category[0].subCategories[0]
              ? product.category[0].subCategories[0]._id
              : null,
        }}
        onSubmit={this.save}
        enableReinitialize
        validationSchema={this.validationSchema}
      >
        {({ isSubmitting, values, isValid, initialValues }) => (
          <Form>
            {fetching && <LinearProgress />}
            <Grid container alignItems="center" justify="space-between" className={classes.header}>
              <Grid item className={classes.headergrid1} xs={12} md={12} lg={6}>
                <Grid container alignItems="center" spacing={2}>
                  <Link to="/products" className={classes.goback}>
                    <GoBackArrow />
                  </Link>
                  <Typography className={classes.gobacktext}>Atualizar produto</Typography>
                </Grid>
              </Grid>
              <Grid container={window.innerWidth < 1200} alignItems="center" justify="space-between">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting || isEqual(initialValues, values)}
                >
                  {isSubmitting ? <CircularProgress size={20} /> : 'Salvar'}
                </Button>
              </Grid>
            </Grid>
            {product && <ProductForm store={store} mode={mode} isCatalog={product.isCatalog} />}
            <Grid container={window.innerWidth < 1200} alignItems="center" justify="space-between" md={12}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting || !isValid || JSON.stringify(initialValues) === JSON.stringify(values)}
              >
                {isSubmitting ? <CircularProgress size={20} /> : 'Salvar'}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    )
  }
}

export default withStyles(styles)(UpdateProductPaper)
