import { Modal } from '@material-ui/core'
import React from 'react'
import Buttons from '../Buttons'
import CatalogTermsIllustration from '../Illustration'
import Info from '../Info'
import { Container } from './styles'

type Props = { leave(): void, stay(): void, open: boolean }

export default function LeaveModal({ leave, stay, open }: Props) {

  return <Modal
    open={open}>
    <Container>
      <CatalogTermsIllustration step="leave" />
      <Info leaving />
      <Buttons next={stay} close={leave} isLeaving />
    </Container>
  </Modal>
}