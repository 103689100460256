import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { BaseApi } from '../../config'
import { IStorageAuth } from '../../interfaces/storageAuth'
import { getCatalogTermsAccepted, postCatalogTermsAccepted, putCatalogTermsAccepted, setURL } from '../../services/api'
import { loadStorage } from '../../services/storage'
import storageKeys from '../../services/storageKeys'
import AuthContext from '../AuthContext'
import snackbarContext from '../SnackbarContext'
import StoreContext from '../StoreContext'

interface CatalogTermsContextInterface {
  accepted?: boolean

  later(): void

  accept(): Promise<boolean>
}

const ctx = createContext<CatalogTermsContextInterface | null>(null)

const laterExpirationKey = 'exp_catalog_terms'

export function CatalogTermsContextProvider({ children }: { children: ReactNode }) {
  const { store } = useContext(StoreContext)
  const [accepted, setAccepted] = useState<boolean | undefined>()
  const { user } = useContext(AuthContext)
  const { openSnackbar } = useContext(snackbarContext)
  useEffect(() => {
    const now = new Date().getTime()
    const exp = Number(localStorage.getItem(laterExpirationKey))
    if (!isNaN(exp) && now < exp) return

    if (store?._id) {
      setURL(BaseApi)
      getCatalogTermsAccepted(store._id)
        .then((res) => {
          if (res.data) {
            setAccepted(res.data?.accepted)
          }
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }, [accepted, store])

  function later() {
    if (!accepted) {
      const twelveHours = 1000 * 60 * 60 * 12
      const now = new Date().getTime()
      localStorage.setItem(laterExpirationKey, String(now + twelveHours))
      setAccepted(undefined)
    }
  }

  const onGetStore = () => loadStorage<IStorageAuth>(storageKeys.AUTH_KEY)

  async function accept() {
    setURL(BaseApi)
    const auth = onGetStore()

    if (store?._id && user?.userName && auth?.store.tenant) {
      await postCatalogTermsAccepted(auth?.store.tenant)
      const res = await putCatalogTermsAccepted({ storeId: store._id, name: user.userName, accepted: true })
      if (!res.data?.error) {
        setAccepted(true)
        return true
      }
      openSnackbar('Não foi possível atualizar o catálogo, tente novamente mais tarde', 'error')
      console.error('error', res.data.error)
      later()
    }
    return false
  }

  return <ctx.Provider value={{ accepted, later, accept }}>{children}</ctx.Provider>
}

export default function useCatalogTerms() {
  const context = useContext(ctx)
  if (!context) throw new Error('Did you forget to use context provider?')
  return context
}
