import { Box, Chip, Divider, FormControl, Grid, MenuItem, TextField, Typography, withStyles } from '@material-ui/core'
import { Field, FieldProps } from 'formik'
import React, { Component } from 'react'
import { RouterProps } from 'react-router-dom'
import PaperBlock from '../../PaperBlock'

// Icons
import { ReactComponent as CloudIcon } from '../../../assets/images/blueCloudUploader.svg'
import { ReactComponent as GreenElipse } from '../../../assets/images/greenElipse.svg'
import { ReactComponent as GreyElipse } from '../../../assets/images/greyElipse.svg'
import Base64 from '../../../helpers/to-base-64'

import SelectField from '../../SelectFormField'
import FormField from '../../TextFormField'

// Interfaces
import { BucketS3 } from '../../../config'
import { validateAndFormatString } from '../../../helpers/validateAndFormatString'
import Category from '../../../interfaces/category'
import { Subcategory } from '../../../services/api/interfaces/ApiResponse'
import CustomDropzone from '../../CustomDropzone'
import { EditSubcategory } from '../../EditSubcategory'
import styles from './styles'

interface Props extends RouterProps {
  classes: Record<keyof ReturnType<typeof styles>, string>
  mode: any
  setFieldValue: any
  values: any
  fetching: boolean
  categories: Category[]
  subCategories: Subcategory[]
  loadCategories: (data: any) => void
}

type State = {
  subCategories: Category[]
  beforeChangeType: string
  setedUpbeforeChangeType: boolean
}

class CategoryForm extends Component<Props, State> {
  static defaultProps = {
    isvalid: false,
    fetching: false,
    subCategories: [],
  }

  state: State = {
    subCategories: [],
    beforeChangeType: '',
    setedUpbeforeChangeType: false,
  }

  componentDidUpdate(prevProps: any) {
    const { categories, subCategories } = this.props

    if (
      categories &&
      categories.length > 0 &&
      subCategories &&
      subCategories.length > 0 &&
      (prevProps.categories !== categories || prevProps.subCategories !== subCategories)
    ) {
      this.setState((state: any) => ({
        ...state,
        categories,
        subCategories,
      }))
    }
  }

  handleImage = async (files: any) => {
    const base64 = await Base64(files[0])

    const image = {
      url: URL.createObjectURL(files[0]),
      content: base64,
    }

    return image
  }

  render() {
    const { classes, values } = this.props
    return (
      <React.Fragment>
        <Grid item lg={9} md={8} xs={12}>
          <div>
            <PaperBlock title="Categoria">
              <Box mb={2}>
                <Field
                  name="name"
                  autoComplete="off"
                  disabled={true}
                  label="Nome da categoria"
                  classes={{
                    root: classes.textfield,
                  }}
                  component={FormField}
                  onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value

                    const category = validateAndFormatString(value)
                    this.props.setFieldValue('name', category)
                  }}
                />
              </Box>
              <Typography color="textSecondary"> Imagem da categoria </Typography>
              <Box mt={1} maxWidth={120} height={120}>
                <Field name="image">
                  {({ form, field }: FieldProps) => (
                    <CustomDropzone
                      onChange={async (file) => {
                        const image = await this.handleImage(file)
                        form.setFieldValue('image', image)
                      }}
                      content={() =>
                        typeof field.value === 'string' ? (
                          <img className={classes.categoryImage} src={BucketS3 + field.value} alt="" />
                        ) : field.value?.content ? (
                          <img className={classes.categoryImage} src={field.value.url} alt="" />
                        ) : (
                          <CloudIcon />
                        )
                      }
                    />
                  )}
                </Field>
              </Box>
            </PaperBlock>
          </div>
          <div>
            <PaperBlock title="Pré-visualização da listagem de mecanismo de pesquisa">
              {!values.metaTitle && !values.metaDescription && !values.CategoryName && (
                <Typography className={classes.caption}>
                  Adicione um título e uma descrição para visualizar como sua categoria pode aparecer em uma listagem de mecanismo
                  de pesquisa
                </Typography>
              )}

              <Typography className={classes.metatitle}>{values.metaTitle}</Typography>
              <Typography className={classes.url}>https://suafarmacia.com.br/departamentos</Typography>
              <Typography className={classes.description}>{values.metaDescription}</Typography>

              <Box mt={3} mb={4}>
                <Divider />
              </Box>
              <Field
                label="titulo da página"
                autoComplete="off"
                name="metaTitle"
                helperText={'Tamanho maximo recomendado 70 caracteres'}
                classes={{
                  root: classes.textfield,
                }}
                component={FormField}
              />
              <Box mt={4}>
                <Field
                  name="metaDescription"
                  autoComplete="off"
                  label="Descrição"
                  helperText={'Tamanho maximo recomendado 150 caracteres'}
                  maxLength={150}
                  classes={{
                    root: classes.descriptionfield,
                  }}
                  component={FormField}
                />
              </Box>
              <Box mt={4} mb={1}>
                <TextField
                  disabled
                  required
                  fullWidth
                  name="url"
                  label={'URL'}
                  variant="outlined"
                  value={values.name ? `https://suafarmacia.com.br/departamentos/${values.name.replace(/\s/g, '-')}` : ''}
                  classes={{ root: classes.textfield }}
                  inputProps={{ maxLength: 320 }}
                />
              </Box>
            </PaperBlock>
          </div>
        </Grid>

        <Grid item lg={3} md={4} xs={12}>
          <div>
            <PaperBlock title="Status da Categoria">
              <FormControl fullWidth style={{ marginTop: '16px' }}>
                <Field
                  classes={{
                    root: classes.select,
                  }}
                  name="status"
                  label="Status da categoria"
                  component={SelectField}
                >
                  <MenuItem value="true">Ativa</MenuItem>
                  <MenuItem value="false">Desativada</MenuItem>
                </Field>
              </FormControl>
              <Box mt={3} mb={3}>
                <Box mb={2}>
                  <Typography className={classes.statstxt}>canais de vendas e apps</Typography>
                </Box>
                <Grid alignItems="center" container spacing={2} wrap="nowrap">
                  <Grid item>{values.status && values.status.toString() === 'true' ? <GreenElipse /> : <GreyElipse />}</Grid>
                  <Grid item>
                    <Typography className={classes.sellingchanneltext}>Loja Virtual - MyPharma PRO</Typography>
                  </Grid>
                </Grid>
                <Grid alignItems="center" container wrap="nowrap" spacing={2}>
                  <Grid item>{values.status && values.status.toString() === 'true' ? <GreenElipse /> : <GreyElipse />}</Grid>
                  <Grid item>
                    <Typography className={classes.sellingchanneltext}>App MyPharma PRO</Typography>
                  </Grid>
                </Grid>
              </Box>
            </PaperBlock>
          </div>

          {/* <div>
            <PaperBlock>
              <Box mb={2} style={{ gap: 8 }} display="flex" alignItems="center">
                <Typography color="primary" style={{ fontSize: 24 }}>
                  Posição da categoria
                </Typography>
                <Tooltip title="Escolha a posição que a categoria ficará na homepage do seu e-commerce. Você deve digitar um número no campo abaixo. Se digitar '1', por exemplo, esta categoria será a primeira da esquerda para a direita, na fileira de categorias.">
                  <HelpOutlineIcon />
                </Tooltip>
              </Box>
              <Field
                name="position"
                autoComplete="off"
                type="number"
                label="Posição da categoria"
                placeholder="Posição (apenas números)"
                classes={{
                  root: classes.textfield,
                }}
                component={FormField}
              />
            </PaperBlock>
          </div> */}

          <div>
            <PaperBlock>
              <Box mb={2} style={{ gap: 8 }} display="flex" alignItems="center" justifyContent="space-between">
                <Typography color="primary" style={{ fontSize: 24 }}>
                  Subcategorias
                </Typography>

                <EditSubcategory />
              </Box>

              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: '4px',
                }}
              >
                {values.subCategories &&
                  values.subCategories.map((subcategory: any, index: number) => (
                    <Box mt={1} key={index}>
                      <Chip
                        label={subcategory.name}
                        style={{
                          backgroundColor: '#00BF91',
                        }}
                      />
                    </Box>
                  ))}
              </Box>
            </PaperBlock>
          </div>
        </Grid>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(CategoryForm)
