import { differenceInMilliseconds } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

interface CalculateDifferenceTimeRequest {
  currentDate: string | Date
  dueDate: string | Date
}

export function calculateDifferenceTime({
  currentDate,
  dueDate,
}: CalculateDifferenceTimeRequest) {
  const currentDateObj = new Date(currentDate)
  const dueDateObj = new Date(dueDate)
  dueDateObj.setDate(dueDateObj.getDate() + 1)

  const dueDateBr = utcToZonedTime(dueDateObj, 'America/Sao_Paulo')

  dueDateBr.setHours(23, 59, 59)

  const currentDateBr = utcToZonedTime(currentDateObj, 'America/Sao_Paulo')

  const differenceMs = differenceInMilliseconds(dueDateBr, currentDateBr)

  const days = (differenceMs / (1000 * 60 * 60 * 24))
  const hours = Math.floor(Math.abs((differenceMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))
  const minutes = Math.floor(Math.abs((differenceMs % (1000 * 60 * 60)) / (1000 * 60)))



  console.log(days, hours, minutes)

  return {
    days,
    hours,
    minutes,
  }
}
