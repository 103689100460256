import { Box, Theme } from '@material-ui/core'
import { styled } from '@material-ui/styles'


export const Container = styled(Box)(({ theme }: {theme: Theme}) => {
  return {
    position: 'absolute' as 'absolute',
      top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '37.5rem',
    borderRadius: '1.5rem',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
  }
})
