import { Box, Button, List, Theme, Typography } from '@material-ui/core'
import { styled } from '@material-ui/styles'


export const ShowModalButton = styled(Button)(({ theme }: { theme: Theme }) => ({
  textTransform: 'none',
  color: '#0b6af2',
  fontWeight: 500,
  '&:hover': {
    background: 'none',
  },
}))

export const Container = styled(Box)(({ theme }: { theme: Theme }) => {
  return {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '37.5rem',
    borderRadius: '1.5rem',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
  }
})

export const TermText = styled(Typography)({
  fontSize: '0.875rem',
})

export const Title = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(2),
  fontWeight: 500,
  color: '#474f57',
}))

export const TermList = styled(List)(({ theme }) => ({
  listStyle: 'decimal',
  paddingLeft: theme.spacing(2),
}))

export const TermListItem = styled('li')(({ theme }: { theme: Theme }) => ({
  display: 'list-item',
}))

export const TermBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  maxHeight: '60vh',
  overflowY: 'scroll',
}))