import React, { useMemo } from 'react'
import { StepEnum } from '../../enum'
import { Description, Title } from './styles'

type Props = {
  step?: number;
  leaving?: boolean;
}
type Content = {
  [key: number | string]: { title: string, description: string }
}
const content: Content = {
  leaving: {
    title: 'Oh não!', description: `Você chegou tão longe, não saia agora... Continue avançando.\n\nFalta pouco!`,
  },
  [-1]: { title: 'Nova Atualização Pendente', description: 'Seu catálogo de produtos precisa ser atualizado!' },
  [StepEnum.BENEFITS]: {
    title: 'Atualização do Catálogo de Produtos',
    description: 'Manter produtos atualizados no e-commerce, incluindo imagem, título e descrição, aumenta a confiança dos clientes, impulsiona as vendas e melhora a reputação da farmácia online.',
  },
  [StepEnum.TERMS]: {
    title: 'Mudanças para melhor',
    description: 'Ao atualizar seus produtos você só tem a ganhar, veja o que muda e siga adiante para continuar com a atualização.',
  },
  [StepEnum.FINISHED]: {
    title: 'Parabéns!',
    description: `Você concluiu a sua atualização do catálogo de produtos. Agora continue avançando na sua jornada.\n\nÓtimas vendas!`,
  },
}
export default function Info({ step, leaving }: Props) {
  const stepInfo = useMemo(() => {
    if (leaving) return content['leaving']
    return step !== undefined ? content[step] : null
  }, [leaving, step])
  return <>
    <Title variant={'h5'}>
      {stepInfo?.title}
    </Title>
    <Description variant={'caption'}>
      {stepInfo?.description}
    </Description>
  </>
}