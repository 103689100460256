/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Field, Formik } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import categoryContext from '../../context/CategoryContext'
import snackbarContext from '../../context/SnackbarContext'
import { validateAndFormatString } from '../../helpers/validateAndFormatString'
import SelectFormField from '../SelectFormField'

const useStyles = makeStyles((theme) => ({
  customInputRoot: {
    borderRadius: 8,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#c7c7c7',
    },
  },
  customInput: {
    height: 32,
  },
  customLabelRoot: {
    top: 12,
  },
  select: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiOutlinedInput-root,.MuiOutlinedInput-root': {
      borderRadius: 8,
      height: '48px',
    },
    '& .MuiInputLabel-formControl': {
      top: 12,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#c7c7c7',
    },
  },
}))

export function EditSubcategory() {
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [showError, setShowError] = useState(false)

  const { getSubCategories, subCategories, requestUpsertSubCategories, getCategoryDetail } = useContext(categoryContext)
  const { openSnackbar } = useContext(snackbarContext)

  const { categoryId } = useParams<{ categoryId: string }>()

  useEffect(() => {
    if (categoryId) {
      getSubCategories(categoryId)
    }
  }, [categoryId])

  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <>
      <Button onClick={handleOpen} variant="text" color="primary">
        GERENCIAR
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <Formik
          initialValues={{ subCategories: subCategories.filter((subcategory) => subcategory.default === false) }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await requestUpsertSubCategories(categoryId, values)
              setSubmitting(false)
              handleClose()
              await getCategoryDetail(categoryId)
              openSnackbar('Subcategorias atualizadas com sucesso', 'success')
            } catch (err: any) {
              console.error(err)
              let errorMessage = 'Erro ao atualizar subcategorias'

              if (err.message) {
                errorMessage = err.message
              }

              openSnackbar(errorMessage, 'error')
            }
          }}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
              <DialogContent style={{ paddingLeft: '24px', paddingRight: '24px', overflowY: 'hidden' }}>
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography
                    style={{
                      fontSize: '24px',
                      color: '#1999F9',
                      fontWeight: '600',
                    }}
                  >
                    Gerenciar sub categorias
                  </Typography>
                </Box>

                <Divider
                  style={{
                    marginTop: '18px',
                    marginBottom: '18px',
                    backgroundColor: '#e9e9e9',
                  }}
                />

                <Box>
                  <Typography
                    style={{
                      fontSize: '20px',
                      fontWeight: 500,
                      marginBottom: '24px',
                    }}
                  >
                    Sub categorias padrões
                  </Typography>

                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      marginBottom: '16px',
                      gap: '16px',
                    }}
                  >
                    {subCategories
                      .filter((subcategory) => subcategory.default === true)
                      .map((subcategory, index) => (
                        <Box key={index}>
                          <Chip
                            label={subcategory.name}
                            style={{
                              padding: '14px',
                              fontSize: '14px',
                              backgroundColor: '#00BF91',
                            }}
                          />
                        </Box>
                      ))}
                  </Box>
                </Box>

                <Divider
                  style={{
                    marginTop: '24px',
                    marginBottom: '18px',
                    backgroundColor: '#e9e9e9',
                  }}
                />

                <Box>
                  <Typography
                    style={{
                      fontSize: '20px',
                      fontWeight: 500,
                      marginBottom: '24px',
                    }}
                  >
                    Sub categorias personalizadas
                  </Typography>

                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                    }}
                  >
                    {values.subCategories.map((subcategory, index) => (
                      <>
                        <Grid key={index} container spacing={2}>
                          <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                            <TextField
                              label="Nome da subcategoria"
                              name={`subCategories.${index}.name`}
                              value={subcategory.name}
                              onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                                const value = event.target.value

                                const subCategory = validateAndFormatString(value)
                                setFieldValue(`subCategories.${index}.name`, subCategory)
                              }}
                              fullWidth
                              required
                              variant="outlined"
                              InputProps={{
                                classes: {
                                  root: classes.customInputRoot,
                                  input: classes.customInput,
                                },
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.customLabelRoot,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <Field
                              component={SelectFormField}
                              label="Status"
                              name={`subCategories.${index}.status`}
                              value={subcategory.status}
                              fullWidth
                              required
                              variant="outlined"
                              classes={{
                                root: classes.select,
                              }}
                            >
                              <MenuItem value="true">Ativa</MenuItem>
                              <MenuItem value="false">Desativada</MenuItem>
                            </Field>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                              label="Título da subcategoria"
                              name={`subCategories.${index}.metaTitle`}
                              value={subcategory.metaTitle}
                              helperText={'Tamanho maximo recomendado 70 caracteres'}
                              fullWidth
                              required
                              variant="outlined"
                              onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue(`subCategories.${index}.metaTitle`, event.target.value)
                              }}
                              InputProps={{
                                classes: {
                                  root: classes.customInputRoot,
                                  input: classes.customInput,
                                },
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.customLabelRoot,
                                },
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                              label="Meta description"
                              name={`subCategories.${index}.metaDescription`}
                              value={subcategory.metaDescription}
                              fullWidth
                              required
                              variant="outlined"
                              onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue(`subCategories.${index}.metaDescription`, event.target.value)
                              }}
                              InputProps={{
                                classes: {
                                  root: classes.customInputRoot,
                                  input: classes.customInput,
                                },
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.customLabelRoot,
                                },
                              }}
                            />
                          </Grid>

                          {(!subcategory.default || subcategory?.isNew) && (
                            <Box
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                width: '100%',
                                marginTop: '8px',
                              }}
                            >
                              <Button
                                onClick={() => {
                                  setFieldValue(
                                    'subCategories',
                                    values.subCategories.filter((item) => item._id !== subcategory._id)
                                  )
                                }}
                              >
                                <Typography
                                  color="error"
                                  style={{
                                    fontWeight: '600',
                                    marginRight: '12px',
                                    fontSize: '12px',
                                  }}
                                >
                                  REMOVER
                                </Typography>
                              </Button>
                            </Box>
                          )}
                        </Grid>
                        {index < values.subCategories.length - 1 && (
                          <Divider
                            style={{
                              marginTop: '12px',
                              marginBottom: '12px',
                              backgroundColor: '#e9e9e9',
                            }}
                          />
                        )}
                      </>
                    ))}
                  </Box>

                  <Button
                    type="button"
                    variant="text"
                    color="primary"
                    onClick={() => {
                      const lastSubcategory =
                        values.subCategories.length > 0 ? values.subCategories[values.subCategories.length - 1] : null

                      if (!lastSubcategory || lastSubcategory.name.trim() !== '') {
                        setFieldValue('subCategories', [
                          ...values.subCategories,
                          { name: '', isNew: true, categoryId, default: false },
                        ])
                        setShowError(false)
                      } else {
                        setShowError(true)
                      }
                    }}
                    style={{
                      marginTop: '16px',
                      fontWeight: '600',
                    }}
                  >
                    Adicionar subcategoria
                  </Button>

                  {showError && (
                    <Typography color="error" style={{ marginTop: '8px' }}>
                      A última subcategoria adicionada deve ter os dados preenchido.
                    </Typography>
                  )}
                </Box>
              </DialogContent>
              <DialogActions
                style={{
                  padding: '24px',
                  display: 'flex',
                  gap: '16px',
                  width: '100%',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'flex-end',
                    gap: '16px',
                    width: '50%',
                  }}
                >
                  <Button
                    style={{
                      padding: '4px 32px',
                    }}
                    variant="outlined"
                    onClick={handleClose}
                  >
                    Cancelar
                  </Button>

                  <Button variant="contained" type="submit" color="primary">
                    Salvar
                  </Button>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  )
}
