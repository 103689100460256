import { Switch } from 'react-router-dom'
import CatalogTermsModal from '../../components/CatalogTermsModal'
import CustomComponent from '../../components/CustomComponent'

import CustomRouter from '../../components/CustomRouter'
import { StartDocksModal } from '../../components/StartDocksModal'
import { CatalogTermsContextProvider } from '../../context/CatalogTermsContext'
import { FinancialProvider } from '../../context/FinancialContext'
import { NotificationProvider } from '../../context/NotificationContext'
import { RouteLinkConsumer, RouteLinkProvider } from '../../context/RouteLinkContext'
import { SnackbarProvider } from '../../context/SnackbarContext'
import { StartDocksConsumer, StartDocksProvider } from '../../context/StartDocksContext'

import { StoreConsumer, StoreProvider } from '../../context/StoreContext'
import routes, { AppRoute } from '../../navigation/index'
import Dashboard from '../Template/Dashboard'

export default class Application extends CustomComponent<{}> {
  render() {
    return (
      <RouteLinkProvider>
        <NotificationProvider>
          <FinancialProvider>
            <StoreProvider>
              <SnackbarProvider>
                <StartDocksProvider>
                  <StartDocksConsumer>{(startDocks) => <StartDocksModal {...startDocks} />}</StartDocksConsumer>
                  <RouteLinkConsumer>
                    {({ links, requestGetRouteLinks }) => (
                      <StoreConsumer>
                        {({ store, requestgetStore }) => (
                          <>
                            <Dashboard links={links} store={store} loadStore={requestgetStore} loadLinks={requestGetRouteLinks}>
                              <CatalogTermsContextProvider>
                                <CatalogTermsModal />
                              </CatalogTermsContextProvider>
                              <Switch>
                                {links.length > 0 &&
                                  store &&
                                  routes
                                    .filter((route: AppRoute) => route.isPrivate || route.path === '/tenant')
                                    .map((route, index) => <CustomRouter store={store} key={index} {...route} />)}
                              </Switch>
                            </Dashboard>
                          </>
                        )}
                      </StoreConsumer>
                    )}
                  </RouteLinkConsumer>
                </StartDocksProvider>
              </SnackbarProvider>
            </StoreProvider>
          </FinancialProvider>
        </NotificationProvider>
      </RouteLinkProvider>
    )
  }
}
