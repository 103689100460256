// tslint:disable: no-shadowed-variable
import { Checkbox, InputAdornment, TextField, Typography, withStyles } from '@material-ui/core'
import React, { Component } from 'react'
import { ReactComponent as GreySearchIcon } from '../../assets/images/greySearchIcon.svg'

import TreeItem from '@material-ui/lab/TreeItem/TreeItem'
import TreeView from '@material-ui/lab/TreeView/TreeView'
import Category from '../../interfaces/category'
import style from './style'

type Props = {
  selected: any[]
  classes: any
  categories: Category[]
  setCategory: (categoryId: string, subCategoryId?: string) => void
  loadCategories: (data?: any) => Promise<void>
}

type State = {
  categories: Category[]
  filters: any
  selectedCategoryId: string
  selectedSubCategoryId: string
}

class CategoryModalContent extends Component<Props, State> {
  static defaultProps = {
    categories: [],
  }

  state: State = {
    filters: {
      type: 'all',
      name: '',
      page: 0,
      limit: 20,
      status: true,
    },
    selectedCategoryId: '',
    selectedSubCategoryId: '',
    categories: [],
  }

  async componentDidMount() {
    await this.onLoadCategories()
  }

  onLoadCategories = async (data?: any) => {
    const { loadCategories } = this.props
    const { filters } = this.state
    const requestData = data || filters
    this.setState(
      (state) => ({
        ...state,
        categories: [],
      }),
      async () => {
        await loadCategories(requestData)
      }
    )
  }

  handleChangeFilters = (field: string, value: string | string[]) => {
    const { filters } = this.state
    const newFilters = {
      ...filters,
      [field]: value,
      page: 0,
    }

    this.setState({ filters: newFilters }, async () => {
      await this.onLoadCategories({ ...newFilters, page: 1 })
    })
  }

  handleCategorySelection = (categoryId: string) => {
    this.setState({ selectedCategoryId: categoryId })
    this.props.setCategory(categoryId)
  }

  handleSubCategorySelection = (subCategoryId: string) => {
    this.setState({ selectedSubCategoryId: subCategoryId })
    this.props.setCategory(this.state.selectedCategoryId, subCategoryId)
  }

  render() {
    const { classes, categories } = this.props
    let count: number = 0
    const { filters, selectedCategoryId, selectedSubCategoryId } = this.state
    return (
      <React.Fragment>
        <TextField
          fullWidth
          margin="dense"
          name="category"
          variant="outlined"
          autoComplete="off"
          value={filters.name || ''}
          label={'Procurar categorias'}
          onChange={({ target }) => this.handleChangeFilters('name', target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <GreySearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <div className={classes.categorycontainer}>
          {categories.map((category) => {
            count++
            return (
              <TreeView className={classes.root} key={category._id}>
                <TreeItem
                  nodeId={count.toString()}
                  label={
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div>
                        <Typography
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          Categoria
                        </Typography>

                        <div>
                          <Checkbox
                            onClick={() => category._id && this.handleCategorySelection(category._id)}
                            checked={selectedCategoryId === category._id}
                          />

                          {category.name}
                        </div>
                      </div>

                      {category.subCategories && category.subCategories?.length > 0 && (
                        <div>
                          <Typography
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Subcategorias
                          </Typography>
                          {category.subCategories.map((subCategory) => {
                            return (
                              <TreeItem
                                key={subCategory._id}
                                nodeId={count.toString()}
                                label={
                                  <div className={classes.childrow}>
                                    <Checkbox
                                      onClick={() => {
                                        if (selectedCategoryId && subCategory._id && category._id === selectedCategoryId) {
                                          this.handleSubCategorySelection(subCategory._id)
                                        }
                                      }}
                                      checked={selectedSubCategoryId === subCategory._id && category._id === selectedCategoryId}
                                      disabled={!selectedCategoryId && category._id !== selectedCategoryId}
                                    />
                                    {subCategory.name}
                                  </div>
                                }
                              />
                            )
                          })}
                        </div>
                      )}
                    </div>
                  }
                  classes={{
                    root: classes.treeitemroot,
                  }}
                />
              </TreeView>
            )
          })}
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(style)(CategoryModalContent)
