import { Typography } from '@material-ui/core'
import { styled } from '@material-ui/styles'

export const Description = styled(Typography)({
  fontSize: '0.875rem',
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
})

export const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 500,
  color: '#474f57',
}))