import { Box, Button as MuiButton, Theme } from '@material-ui/core'
import { styled } from '@material-ui/styles'


export const Button = styled(MuiButton)({
  textTransform: 'none',
  width: '12.25rem',
})
export const ButtonsWrapper = styled(Box)(({ theme }: { theme: Theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
  gap: theme.spacing(1),
}))

export const GreenButton = styled(Button)({
  background: '#1e9c33',
  color: 'white',
})
export const RedButton = styled(Button)({
  background: '#f03e3e',
  color: 'white',
})