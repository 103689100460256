import { Step, StepLabel } from '@material-ui/core'
import { CatalogTermsStepper } from './styles'

type Props = {
  steps: string[],
  activeStep: number;
}

export default function Stepper({activeStep,steps}: Props) {

  return <CatalogTermsStepper activeStep={activeStep} alternativeLabel >
    {steps.map((label) => (
      <Step style={{width: 48}} key={label}>
        <StepLabel>{label}</StepLabel>
      </Step>
    ))}
  </CatalogTermsStepper>
}