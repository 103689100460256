import { Modal } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import useCatalogTerms from '../../context/CatalogTermsContext'
import Buttons from './components/Buttons'
import CatalogTermsIllustration from './components/Illustration'
import Info from './components/Info'
import LeaveModal from './components/LeaveModal'
import Progress from './components/Progress'
import Stepper from './components/Stepper'
import TermsButton from './components/TermsButton'
import { StepEnum } from './enum'
import { Container } from './styles'

const stepTitles = [
  'Benefícios',
  'Aceite',
  'Atualização',
]

export default function CatalogTermsModal() {
  const [showModal, setShowModal] = useState(false)
  const [showLeaveModal, setShowLeaveModal] = useState(false)
  const [step, setStep] = useState(-1)
  const { accepted, later, accept } = useCatalogTerms()

  useEffect(() => {
    if (accepted === false) {
      setShowModal(true)
    }
  }, [accepted])

  const closeModal = useCallback(() => {
    later()
    setStep(StepEnum.PRESENTATION)
    setShowLeaveModal(false)
    setShowModal(false)
  }, [later])

  const handleAccept = useCallback(async () => {
    const success = await accept()
    if (!success) {
      closeModal()
    }
  }, [accept, closeModal])

  useEffect(() => {
    if (step === StepEnum.UPDATING) {
      handleAccept().then()
    }
  }, [handleAccept, step])

  async function handleClickNext() {
    if (step < StepEnum.FINISHED) {
      setStep(prevStep => ++prevStep)
      return
    }
    closeModal()
  }

  function handleClickClose() {
    if (step === StepEnum.PRESENTATION) {
      closeModal()
      return
    }
    setShowModal(false)
    setShowLeaveModal(true)
  }


  function closeLeaveModal() {
    setShowModal(true)
    setShowLeaveModal(false)
  }

  return <><Modal
    open={showModal}
  >
    <Container>
      <CatalogTermsIllustration step={step === -1 ? 'info' : step < 3 ? 'loading' : 'finish'} />
      {step > StepEnum.PRESENTATION && step < StepEnum.FINISHED && <Stepper activeStep={step} steps={stepTitles} />}
      {step !== StepEnum.UPDATING ? <Info step={step} /> : <Progress onFinish={handleClickNext} />}
      {step === StepEnum.TERMS && <TermsButton handleContinue={handleClickNext} step={step} />}

      <Buttons step={step} close={handleClickClose} next={handleClickNext} isLast={step === StepEnum.FINISHED} />
    </Container>
  </Modal>
    <LeaveModal leave={closeModal} stay={closeLeaveModal} open={showLeaveModal} />
  </>

}

