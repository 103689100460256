import { Modal } from '@material-ui/core'
import React, { useState } from 'react'
import shieldBlue from '../../../../assets/images/shieldBlue.svg'
import Buttons from '../Buttons'
import { Container, ShowModalButton, TermBox, TermList, TermListItem, TermText, Title } from './styles'

type Props = {
  handleContinue(): void;
  step: number
}

export default function TermsButton({ handleContinue, step }: Props) {
  const [showTerms, setShowTerms] = useState(false)

  function back() {
    setShowTerms(false)
  }

  function handleClickContinue() {
    setShowTerms(false)
    handleContinue()
  }

  function handleClickShowTerms() {
    setShowTerms(true)
  }

  return <><ShowModalButton onClick={handleClickShowTerms} disableRipple
                            startIcon={<img src={shieldBlue} alt={''} width={18} height={22}
                            />} variant={'text'}>Clique e veja
    as políticas e regras de atualização</ShowModalButton>
    <Modal
      open={showTerms}>
      <Container>
        <Title variant={'h5'}>Políticas e regras de atualização do catálogo de produtos MyPharma</Title>
        <TermBox>
          <TermText variant={'caption'}>
            Ao utilizar nosso catálogo automatizado, que conta com mais de 250 mil produtos em constante evolução, o
            usuário concorda com os seguintes termos:
            <TermList>
              <TermListItem> <b>Atualizações Diárias e Eficientes</b>: Nosso catálogo é atualizado diariamente para
                garantir a
                eficiência e a precisão das informações. Isso inclui a otimização de SEO e uma ampla variedade de
                produtos, desde remédios até itens de perfumaria.
              </TermListItem>
              <li><b>Preservação da Identidade Única</b>: As personalizações feitas na loja serão mantidas, sendo
                sobrescritos apenas os dados específicos dos produtos, como nome, descrição e categoria.
              </li>
              <li><b>Foco Específico</b>: Os 250 mil produtos oferecidos abrangem diversas categorias, como
                medicamentos,
                produtos de beleza, cosméticos e itens de conveniência.
              </li>
              <li><b>Categorização e Classificação dos Produtos</b>: Os produtos serão fornecidos devidamente
                categorizados e classificados. No entanto, é possível que alguns produtos não estejam categorizados.
                Nesse caso, solicitamos que nos informe para que possamos realizar as devidas atualizações.
              </li>
              <li><b>Impactos da Atualização</b>: Durante o processo de atualização, campos específicos dos produtos
                serão
                alterados, como nome, descrição, categoria, imagem, entre outros. As imagens de medicamentos tarjados
                seguirão as regras estabelecidas pela ANVISA.
              </li>
              <li><b>Tempo de Atualização</b>: O tempo necessário para a atualização pode variar de acordo com a
                quantidade de produtos, mas garantimos que as atualizações serão realizadas diariamente.
              </li>
              <li><b>Aviso Prévio</b>: As atualizações serão feitas diariamente, sem aviso prévio.</li>
            </TermList>
            Ao aceitar estes termos, o usuário reconhece que a automação do catálogo visa impulsionar a eficiência da
            loja, atraindo mais clientes e aumentando a fidelização dos mesmos.
          </TermText>
        </TermBox>
        <Buttons step={step} next={handleClickContinue} close={back} alternativeCloseLabel={'Voltar'} />
      </Container>
    </Modal>
  </>
}