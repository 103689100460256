import { Box, Chip, Divider, Grid, MenuItem, TextField, Tooltip, Typography, withStyles } from '@material-ui/core'
import React, { Component } from 'react'
import PaperBlock from '../../PaperBlock'

import ProductImage from '../ProductImage'
// import ProductInsight from '../ProductInsight'
import ProductDescriptionEditor from '../../ProductDescriptionEditor'

import style from './style'

import { ReactComponent as GreenElipse } from '../../../assets/images/greenElipse.svg'
import { ReactComponent as Elipse } from '../../../assets/images/greyElipse.svg'

import Store from '../../../interfaces/store'

import { CircularProgress } from '@material-ui/core'
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab'
import { Field, FieldArray, FieldArrayRenderProps, FieldProps } from 'formik'
import { decode } from 'html-entities'
import { CategoryConsumer } from '../../../context/CategoryContext'
import { FileConsumer, FileProvider } from '../../../context/FileContext'
import Category from '../../../interfaces/category'
import { Subcategory } from '../../../services/api/interfaces/ApiResponse'
import ContainerErrors from '../../ContainerErrors'
import CurrencyTextField from '../../CurrencyTextField'
import SelectFormField from '../../SelectFormField'
import SwitchFormField from '../../SwitchFormField'
import TextFormField from '../../TextFormField'

type Props = {
  classes: any
  mode: any
  store?: Store
  isCatalog?: boolean
}

class ProductForm extends Component<Props> {
  render() {
    const { mode, classes, store, isCatalog } = this.props

    return (
      <CategoryConsumer>
        {({ categorys, getCategorys, subCategories, getSubCategories, fetching }) => (
          <React.Fragment>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={9} md={12}>
                <div>
                  <PaperBlock>
                    {/* ProductMainInfo */}

                    <section className={classes.section}>
                      <Typography className={classes.title}>Produto</Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={12} lg={4} md={4}>
                          {isCatalog && (
                            <div style={{ marginBottom: '8px' }}>
                              <span
                                style={{
                                  color: '#d97706',
                                  fontSize: '12px',
                                  fontWeight: 500,
                                }}
                              >
                                EAN bloqueado devido a restrições do catálogo.
                              </span>
                            </div>
                          )}

                          <Field
                            classes={{
                              root: classes.textfield,
                            }}
                            label="Código de Barras"
                            name="EAN"
                            disabled={isCatalog}
                            autoComplete="off"
                            component={TextFormField}
                          />
                        </Grid>
                        <Grid item xs={12} lg={4} md={4}>
                          {isCatalog && (
                            <div style={{ marginBottom: '8px' }}>
                              <span
                                style={{
                                  color: '#d97706',
                                  fontSize: '12px',
                                  fontWeight: 500,
                                }}
                              >
                                Nome bloqueado devido a restrições do catálogo.
                              </span>
                            </div>
                          )}

                          <Field
                            className={classes.textfield}
                            component={TextFormField}
                            label="Nome"
                            name="name"
                            disabled={isCatalog}
                            autoComplete="off"
                          />
                        </Grid>
                        <Grid item xs={12} lg={4} md={4}>
                          {isCatalog && (
                            <div style={{ marginBottom: '8px' }}>
                              <span
                                style={{
                                  color: '#d97706',
                                  fontSize: '12px',
                                  fontWeight: 500,
                                }}
                              >
                                Apresentação bloqueado devido a restrições do catálogo.
                              </span>
                            </div>
                          )}

                          <Field
                            fullWidth
                            classes={{
                              root: classes.textfield,
                            }}
                            component={TextFormField}
                            label="Apresentação"
                            disabled={isCatalog}
                            name="presentation"
                            autoComplete="off"
                          />
                        </Grid>
                      </Grid>
                    </section>
                    <section>
                      <Typography className={classes.title}>Descrição</Typography>
                      <FileProvider>
                        <FileConsumer>
                          {({ requestAddImage, image }) => (
                            <Field name="description">
                              {({ field, form }: FieldProps) => (
                                <ProductDescriptionEditor
                                  setDescription={(description: string) => form.setFieldValue('description', description)}
                                  description={decode(field.value, {
                                    level: 'all',
                                  })}
                                  image={image}
                                  uploadImage={requestAddImage}
                                />
                              )}
                            </Field>
                          )}
                        </FileConsumer>
                      </FileProvider>
                    </section>
                  </PaperBlock>
                </div>
                <div>
                  <ProductImage mode={mode} classes={classes} />
                </div>
                <div>
                  <PaperBlock>
                    <section className={classes.section}>
                      <Typography className={classes.title}>Mais configurações</Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={12} lg={6} md={6}>
                          {isCatalog && (
                            <div style={{ marginBottom: '8px' }}>
                              <span
                                style={{
                                  color: '#d97706',
                                  fontSize: '12px',
                                  fontWeight: 500,
                                }}
                              >
                                Código MS bloqueado devido a restrições do catálogo.
                              </span>
                            </div>
                          )}

                          <Field
                            label="Código do Ministério da Saúde"
                            name="MS"
                            disabled={isCatalog}
                            classes={{
                              root: classes.textfield,
                            }}
                            component={TextFormField}
                            autoComplete="off"
                          />
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                          {isCatalog && (
                            <div style={{ marginBottom: '8px' }}>
                              <span
                                style={{
                                  color: '#d97706',
                                  fontSize: '12px',
                                  fontWeight: 500,
                                }}
                              >
                                Fabricante bloqueado devido a restrições do catálogo.
                              </span>
                            </div>
                          )}
                          <Field
                            classes={{
                              root: classes.textfield,
                            }}
                            component={TextFormField}
                            label="Fabricante"
                            disabled={isCatalog}
                            name="manufacturerName"
                            autoComplete="off"
                          />
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                          {isCatalog && (
                            <div style={{ marginBottom: '8px' }}>
                              <span
                                style={{
                                  color: '#d97706',
                                  fontSize: '12px',
                                  fontWeight: 500,
                                }}
                              >
                                Controle bloqueado devido a restrições do catálogo.
                              </span>
                            </div>
                          )}

                          <Field
                            name="controlName"
                            label="Controle"
                            disabled={isCatalog}
                            classes={{
                              root: classes.textfield,
                            }}
                            component={TextFormField}
                            autoComplete="off"
                          />
                        </Grid>

                        <Grid item xs={12} lg={6} md={6}>
                          {isCatalog && (
                            <div style={{ marginBottom: '8px' }}>
                              <span
                                style={{
                                  color: '#d97706',
                                  fontSize: '12px',
                                  fontWeight: 500,
                                }}
                              >
                                Classificação bloqueado devido a restrições do catálogo.
                              </span>
                            </div>
                          )}

                          <Field
                            classes={{
                              root: classes.textfield,
                            }}
                            component={TextFormField}
                            label="Classificação"
                            disabled={isCatalog}
                            name="classificationName"
                            autoComplete="off"
                          />
                        </Grid>

                        <Grid item xs={12} lg={6} md={6}>
                          {isCatalog && (
                            <div style={{ marginBottom: '8px' }}>
                              <span
                                style={{
                                  color: '#d97706',
                                  fontSize: '12px',
                                  fontWeight: 500,
                                }}
                              >
                                Princípio Ativo bloqueado devido a restrições do catálogo.
                              </span>
                            </div>
                          )}

                          <Field
                            classes={{
                              root: classes.textfield,
                            }}
                            component={TextFormField}
                            label="Princípio Ativo"
                            disabled={isCatalog}
                            name="activePrinciple"
                            autoComplete="off"
                          />
                        </Grid>

                        <Grid item xs={12} lg={6} md={6}>
                          {isCatalog && (
                            <div style={{ marginBottom: '8px' }}>
                              <span
                                style={{
                                  color: 'transparent',
                                  fontSize: '12px',
                                  fontWeight: 500,
                                }}
                              >
                                Estoque
                              </span>
                            </div>
                          )}

                          <Field
                            classes={{
                              root: classes.textfield,
                            }}
                            label="Estoque"
                            name="quantity"
                            type="number"
                            component={TextFormField}
                            autoComplete="off"
                          />
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                          <Field name="price">
                            {({ field, form }: FieldProps) => (
                              <React.Fragment>
                                <CurrencyTextField
                                  prefix="R$ "
                                  value={field.value}
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  label="Preço Promocional"
                                  className={classes.currencyinput}
                                  onChange={({ floatValue }) => form.setFieldValue('price', floatValue)}
                                />
                                <ContainerErrors name="price" errors={form.errors} />
                              </React.Fragment>
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                          <Field name="erp_pmc">
                            {({ field, form }: FieldProps) => (
                              <React.Fragment>
                                <CurrencyTextField
                                  prefix="R$ "
                                  value={field.value}
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  label="Preço do Produto"
                                  className={classes.currencyinput}
                                  onChange={({ floatValue }) => form.setFieldValue('erp_pmc', floatValue)}
                                />
                                <ContainerErrors name="erp_pmc" errors={form.errors} />
                              </React.Fragment>
                            )}
                          </Field>
                        </Grid>
                      </Grid>
                    </section>
                  </PaperBlock>
                </div>

                <div>
                  <PaperBlock>
                    <section className={classes.section}>
                      <Typography className={classes.title}>Pesos e dimensões</Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={12} lg={12} md={12}>
                          <Typography className={classes.caption}>
                            vamos usá-los para calcular o custo de envio. Pese e meça o seu produto com a embalagem ( aquela que
                            voce utiliza, não a da empresa de frete ).
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                          <Field name="weight">
                            {({ field, form }: FieldProps) => (
                              <CurrencyTextField
                                suffix=" kg"
                                className={classes.currencyinput}
                                label="Peso"
                                precision="3"
                                thousandSeparator="."
                                decimalSeparator="."
                                onChange={({ floatValue }) => form.setFieldValue('weight', floatValue)}
                                value={field.value}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                          <Field name="length">
                            {({ field, form }: FieldProps) => (
                              <CurrencyTextField
                                suffix=" cm"
                                precision="1"
                                className={classes.currencyinput}
                                label="Comprimento"
                                thousandSeparator="."
                                decimalSeparator="."
                                onChange={({ floatValue }) => form.setFieldValue('length', floatValue)}
                                value={field.value}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                          <Field name="width">
                            {({ field, form }: FieldProps) => (
                              <CurrencyTextField
                                suffix=" cm"
                                precision="1"
                                className={classes.currencyinput}
                                label="Largura"
                                thousandSeparator="."
                                decimalSeparator="."
                                onChange={({ floatValue }) => form.setFieldValue('width', floatValue)}
                                value={field.value}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                          <Field name="height">
                            {({ field, form }: FieldProps) => (
                              <CurrencyTextField
                                suffix=" cm"
                                precision="1"
                                className={classes.currencyinput}
                                label="Altura"
                                thousandSeparator="."
                                decimalSeparator="."
                                onChange={({ floatValue }) => form.setFieldValue('height', floatValue)}
                                value={field.value}
                              />
                            )}
                          </Field>
                        </Grid>
                      </Grid>
                    </section>
                  </PaperBlock>
                </div>

                {/* Product Visualization */}

                <div>
                  <PaperBlock>
                    <Typography className={classes.title}>Pré-visualização da listagem de mecanismo de pesquisa</Typography>
                    <Typography className={classes.caption}>
                      Adicione um título e uma descrição para visualizar como produto pode aparecer em uma listagem de mecanismo
                      de pesquisa
                    </Typography>
                    <Divider className={classes.divider} />
                    <section className={classes.section}>
                      <Field name="metaTitle">
                        {({ field }: FieldProps) => (
                          <TextField
                            classes={{
                              root: classes.textfield,
                            }}
                            fullWidth
                            disabled={isCatalog}
                            helperText={`${field.value?.length} de 70 caracteres usados`}
                            variant="outlined"
                            inputProps={{
                              maxLength: 70,
                            }}
                            label="Título da página"
                            {...field}
                            autoComplete="off"
                          />
                        )}
                      </Field>
                    </section>
                    <section className={classes.section}>
                      <Field name="metaDescription">
                        {({ field }: FieldProps) => (
                          <TextField
                            multiline
                            rows={3}
                            classes={{
                              root: classes.textarea,
                            }}
                            fullWidth
                            disabled={isCatalog}
                            helperText={`${field.value ? field.value.length : 0} de 160 caracteres usados`}
                            variant="outlined"
                            inputProps={{
                              maxLength: 160,
                            }}
                            label="Descrição"
                            {...field}
                            autoComplete="off"
                          />
                        )}
                      </Field>
                    </section>
                    <section className={classes.section}>
                      <Field name="url" style={{ width: '100%' }}>
                        {({ form }: FieldProps) => (
                          <TextField
                            fullWidth
                            classes={{
                              root: classes.textfield,
                            }}
                            variant="outlined"
                            label="URL"
                            disabled={isCatalog}
                            value={`${store?.url}produtos/${
                              form.values.slug.length > 0 ? form.values.slug[form.values.slug.length - 1] : ''
                            }`}
                          />
                        )}
                      </Field>
                    </section>
                  </PaperBlock>
                </div>
              </Grid>
              <Grid item lg={3} md={12}>
                {/* Product Status */}

                <div>
                  <PaperBlock>
                    <Typography className={classes.title}>Status do Produto</Typography>
                    <section className={classes.section}>
                      <Field name="status" label="Status" component={SelectFormField}>
                        <MenuItem value="false">Não publicado</MenuItem>
                        <MenuItem value="true">Publicado</MenuItem>
                      </Field>
                    </section>
                    <section className={classes.section}>
                      <Typography className={classes.boldtext}>canais de vendas e apps</Typography>
                    </section>
                    <section className={classes.section}>
                      {
                        <Field name="status">
                          {({ field }: FieldProps) => (
                            <React.Fragment>
                              <section className={classes.section}>
                                <Grid alignItems="center" container>
                                  {field.value ? <GreenElipse /> : <Elipse />}
                                  <Typography className={classes.sellingchanneltext}>Loja Virtual - MyPharma PRO</Typography>
                                </Grid>
                              </section>
                              <Grid alignItems="center" container>
                                {field.value ? <GreenElipse /> : <Elipse />}
                                <Typography className={classes.sellingchanneltext}>App MyPharma PRO</Typography>
                              </Grid>
                            </React.Fragment>
                          )}
                        </Field>
                      }
                    </section>
                  </PaperBlock>
                </div>
                {
                  // <ProductInsight product={product} mode={mode} classes={classes} />
                }

                {/* Product Organization */}

                <div>
                  <PaperBlock>
                    <Typography className={classes.title}>Categoria</Typography>

                    <section className={classes.section}>
                      <Box mb={1}>
                        <FieldArray
                          name="category"
                          render={({ form, push, remove }: FieldArrayRenderProps) => (
                            <>
                              {!isCatalog ? (
                                <Autocomplete
                                  value={form.values.category}
                                  options={categorys}
                                  renderInput={(params: AutocompleteRenderInputParams) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Categoria"
                                      placeholder="Procure a categoria"
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {fetching && <CircularProgress color="primary" size={20} />}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  )}
                                  getOptionDisabled={(option: Category) =>
                                    form.values.category.find((category: Category) => category._id === option._id) ? true : false
                                  }
                                  getOptionLabel={(op: Category) => op.name}
                                  onChange={async (ev: any, category: Category | null) => {
                                    if (category) {
                                      form.setFieldValue('category', [category])
                                      await getSubCategories(category._id)
                                    }
                                  }}
                                  className={classes.autocomplete}
                                  onInputChange={(e: React.ChangeEvent<{}>, value: string) => {
                                    getCategorys({ name: value })
                                  }}
                                />
                              ) : (
                                <Grid container>
                                  {form.values.category.map((category: Category, index: number) => (
                                    <Box ml={index !== 0 ? 1 : 0} mt={1} key={index}>
                                      {isCatalog ? (
                                        category.name ? (
                                          <Chip label={category.name} />
                                        ) : (
                                          <Typography
                                            style={{
                                              color: '#d97706',
                                              fontSize: '16px',
                                              fontWeight: 500,
                                            }}
                                          >
                                            Este produto do catálogo está sem uma categoria atribuída. Por favor, informe este
                                            problema ao nosso suporte para que possamos corrigi-lo. Agradecemos a sua colaboração!
                                          </Typography>
                                        )
                                      ) : (
                                        <Chip label={category.name} onDelete={() => remove(index)} />
                                      )}
                                    </Box>
                                  ))}
                                </Grid>
                              )}
                            </>
                          )}
                        />

                        <FieldArray
                          name="category"
                          render={({ form, remove }: FieldArrayRenderProps) => (
                            <Grid container>
                              {form.values.category &&
                                form.values.category.length > 0 &&
                                form.values.category.map((category: Category, index: number) => (
                                  <Box ml={index !== 0 ? 1 : 0} mt={1} key={index}>
                                    <Chip label={category.name} onDelete={() => remove(index)} />
                                  </Box>
                                ))}
                            </Grid>
                          )}
                        />
                      </Box>

                      <Typography className={classes.subtitle}>Subcategoria</Typography>

                      <Box mb={1}>
                        <Field name="subcategory" label="Selecione uma subcategoria" component={SelectFormField}>
                          {subCategories.map((subCategory: Subcategory) => (
                            <MenuItem key={subCategory._id} value={subCategory._id}>
                              {subCategory.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </Box>
                    </section>
                  </PaperBlock>
                </div>

                <div>
                  <PaperBlock>
                    <Typography className={classes.title}>Organização</Typography>

                    <Field
                      name="priceLocked"
                      label="Travar Preço Promocional do Produto perante integração"
                      labelPlacement="start"
                      component={SwitchFormField}
                    />
                    <Field
                      name="quantityLocked"
                      label="Travar Estoque Atual do Produto perante integração"
                      labelPlacement="start"
                      component={SwitchFormField}
                    />
                    <Tooltip title="Se ativo sera mostrado o valor aqui definido">
                      <Field
                        name="manualPMC"
                        label="Travar Preço do Produto perante a integração"
                        labelPlacement="start"
                        component={SwitchFormField}
                      />
                    </Tooltip>
                  </PaperBlock>
                </div>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </CategoryConsumer>
    )
  }
}

export default withStyles(style)(ProductForm)
