import { useTheme } from '@material-ui/core'
import React, { ImgHTMLAttributes } from 'react'
import ghostRed from '../../../../assets/images/ghostRed.svg'
import happyFriendsGreen from '../../../../assets/images/happyFriendsGreen.svg'
import loadingBlue from '../../../../assets/images/ilustration/loading2.svg'
import infoBlue from '../../../../assets/images/infoBlue.svg'

type Step = 'info' | 'loading' | 'finish' | 'leave'
type Props = {
  step: Step
}
const illustration: {
  [key: string]: Pick<ImgHTMLAttributes<HTMLImageElement>, 'src' | 'alt'> & { large?: boolean }
} = {
  loading: { src: loadingBlue, alt: 'Carregamento em azul' },
  info: { src: infoBlue, alt: 'I dentro de um círculo em azul' },
  finish: { src: happyFriendsGreen, alt: 'Dois circulos verdes felizes', large: true },
  leave: { src: ghostRed, alt: 'Fantasma vermelho', large: true },
}
export default function CatalogTermsIllustration({ step }: Props) {
  const theme = useTheme()
  const illustrationInfo = illustration[step]
  return <img {...illustrationInfo} alt={''} width={illustrationInfo.large ? 280 : 180}
              style={{ marginBottom: theme.spacing(2) }} />
}