import React, { useMemo } from 'react'
import { StepEnum } from '../../enum'
import { Button, ButtonsWrapper, GreenButton, RedButton } from './styles'

type Props = {
  step?: number;
  next(): void;
  close(): void;
  alternativeCloseLabel?: string,
  isLeaving?: boolean;
  isLast?: boolean;
}
type StepButtons = { [key: number]: { close: string, next: string, show?: boolean } }
const stepButtons: StepButtons = {
  [StepEnum.PRESENTATION]: { close: 'Agora não', next: 'Continuar' },
  [StepEnum.BENEFITS]: { close: 'Sair', next: 'Continuar' },
  [StepEnum.TERMS]: { close: 'Cancelar', next: 'Aceitar e Continuar' },
}

export default function Buttons({ alternativeCloseLabel, step, next, close, isLeaving, isLast }: Props) {
  const stepInfo = useMemo(() => step !== undefined ? stepButtons[step] : null, [step])
  if (!stepInfo && !isLast && !isLeaving) return null
  return <ButtonsWrapper>
    {isLast && <GreenButton onClick={next} variant={'contained'}
                            size={'large'} color={'primary'}>Seguir em frente!</GreenButton>}
    {isLeaving && <>
      <RedButton onClick={close} variant={'contained'}
                 size={'large'} color={'primary'}>Não quero avançar</RedButton>
      <GreenButton onClick={next} variant={'contained'} size={'large'}
                   color={'primary'}>Ok! Seguir em frente!</GreenButton>
    </>}

    {stepInfo && <>
      <Button onClick={close} variant={'contained'}
              size={'large'}>{alternativeCloseLabel ? alternativeCloseLabel : stepInfo.close}</Button>
      <Button onClick={next} variant={'contained'} size={'large'}
              color={'primary'}>{stepInfo.next}</Button>
    </>}

  </ButtonsWrapper>
}